.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.message {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box {
  flex-basis: 50%;
  flex-grow: unset;
  height: 150px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 10px;
  border: 1px solid;
  font-size: xx-large;
}

.shipper {
  float: left;
}

.fleet {
  float: right;
}

.container ion-icon {
  font-size: 32px;
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}

.alert-tappable.alert-radio {
  height: auto;
  contain: content;
}

.header-row {
  background-color: var(--ion-color-primary);
  font-size: 20px;
}